import React from "react";

import { shape, string } from "prop-types";
import { GloryFlex, GloryImg } from "components";
import { Palette } from "style";
import { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { store } from "store";
import { appActions } from "store/App/app.slice";

const activeStyle = {
  opacity: 0.5,
};
const constantStyle = {
  textDecoration: "none",
  fontSize: "2rem",
  fontWeight: "bold",
  color: Palette.white,
  width: "100%",
  opacity: 1,
};

function SideMenuItem({ label, style, icon, to }) {
  const { dispatch } = store;

  const refreshCurrent = () => {
    // known labels:
    // Home. Downlaods. Road map.

    switch (label) {
      case "Downloads":
        dispatch(appActions.getAllVersions());
        dispatch(appActions.getLatestVersionStatus());
        break;

      case "Road map":
        dispatch(appActions.getUpcomingAppVersions());
        break;

      default:
    }
  };

  return (
    <GloryFlex
      flex="unset"
      background={Palette.lightRed}
      padding="0 1.5rem"
      style={{ borderRadius: "5px", ...style }}
    >
      <NavLink
        style={({ isActive }) => ({
          ...constantStyle,
          ...(isActive ? activeStyle : {}),
        })}
        to={to}
        onClick={refreshCurrent}
      >
        {label}
      </NavLink>
      {icon ? <GloryImg src={icon} alt={`${label}-icon`} /> : null}
    </GloryFlex>
  );
}

SideMenuItem.propTypes = {
  label: string.isRequired,
  style: shape(css),
  icon: string,
  to: string.isRequired,
};

SideMenuItem.defaultProps = {
  style: {},
  icon: "",
};

export default SideMenuItem;
