import { GloryFlex, GloryLink, GlorySpace, GloryText } from "components";
import { format } from "date-fns";
import FeatureHighlight from "pages/home/components/main/FeatureHighlight";
import React from "react";
import { useSelector } from "react-redux";
import StyledHeader from "shared/header/TitleWithIcon";
import { appSelectors } from "store/App/app.selector";
import { Palette } from "style";
import styled from "styled-components";

const CustomContainer = styled.div`
  width: 90%;
  position: relative;
  &::after {
    content: "";
    width: 50%;
    height: 2px;
    background-color: #e1bc48;
    position: absolute;
    bottom: 0;
    left: 25%;
    margin: 0 auto;
  }
`;

const ResponsiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin: 4rem auto;
  width: 100%;
`;

function Roadmap() {
  const upcomingVersions = useSelector(appSelectors.selectUpcomingVersions);

  return (
    <GloryFlex flexDirection="column" width="100%">
      <ResponsiveWrapper>
        <GloryFlex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="2rem"
        >
          <StyledHeader />
          <GloryText
            size="xsmall"
            style={{ width: "80%", maxWidth: "90rem", padding: "0 1rem" }}
            align="center"
          >
            Please note this roadmap is subject to change and some features may
            be worked on or even released earlier or later than expected in this
            roadmap. It is meant to give you an idea of what is to come in the
            coming months, not a guarantee. None of these milestones have a
            date, they may have a rough estimate but this is never guaranteed!
          </GloryText>
        </GloryFlex>

        <CustomContainer>
          <GloryFlex
            style={{
              position: "relative",
              minHeight: "5rem",
            }}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {upcomingVersions.map(
              ({ appVersion, description, name, eta, live }, index) => (
                <FeatureHighlight speed="0.5s" threshold={0.5}>
                  <GloryFlex
                    key={appVersion}
                    style={{ margin: "0 2rem 0 0" }}
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={index % 2 === 0 ? "row-reverse" : "row"}
                    width="100%"
                  >
                    <GloryFlex
                      flexDirection={index % 2 !== 0 ? "row-reverse" : "row"}
                      flex={1}
                      width="50%"
                      style={{
                        paddingBottom: "20rem",
                        boxShadow: `${
                          index % 2 === 0
                            ? "2px 0 0 0 #ff0000 inset"
                            : "2px 0 0 0 #e1bc48"
                        }`,
                      }}
                    >
                      <GloryFlex
                        style={{
                          flexGrow: "revert",
                          flexShrink: "revert",
                          flexBasis: "revert",
                          background:
                            index % 2 === 0 ? Palette.lightRed : Palette.gold,
                          width: "2rem",
                          right: index % 2 === 0 ? 0 : -2,
                          position: "relative",
                        }}
                        height="2px"
                      />
                      <GloryFlex
                        flexDirection="column"
                        style={{
                          position: "relative",
                          bottom: "10px",
                        }}
                      >
                        <GloryText
                          style={{
                            alignSelf:
                              index % 2 !== 0 ? "flex-end" : "flex-start",
                          }}
                          size="large"
                          color="gold"
                          align="right"
                        >
                          {appVersion}
                        </GloryText>
                        {live ? (
                          <GloryText size="small">
                            This version is live and can be downloaded
                            <GloryLink
                              href={`${process.env.REACT_APP_baseURL}/version/${appVersion}/changelog`}
                              style={{
                                color: Palette.gold,
                                textDecoration: "underline",
                                fontWeight: "bold",
                                width: "4rem",
                              }}
                              textAlign="center"
                            >
                              {` here`}
                            </GloryLink>
                          </GloryText>
                        ) : eta ? (
                          <GloryText size="small">
                            {`ETA:${format(
                              new Date(),
                              "RRRR - MMM - do"
                            ).toString()}`}
                          </GloryText>
                        ) : null}
                        <GloryText>{name}:</GloryText>
                        <GlorySpace h="0.5rem" />
                        <ul>
                          {description.length
                            ? description.map((x) => (
                                <li
                                  style={{
                                    color: "white",
                                    padding: 0,
                                    marginBottom: "1rem",
                                  }}
                                  key={x}
                                >
                                  <GloryText>{x?.description}</GloryText>
                                </li>
                              ))
                            : null}
                        </ul>
                      </GloryFlex>
                    </GloryFlex>
                    <GloryFlex style={{ flex: 1, width: "50%" }} />
                  </GloryFlex>
                </FeatureHighlight>
              )
            )}
          </GloryFlex>
        </CustomContainer>
      </ResponsiveWrapper>
    </GloryFlex>
  );
}

export default Roadmap;
