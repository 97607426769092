import React from "react";

import { func, number, oneOf, oneOfType, shape, string } from "prop-types";
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import { FONTALIGN, FONTSIZE, FONTWEIGHT, TextColors } from "style";

function GloryText({
  text,
  size,
  color,
  weight,
  align,
  children,
  className,
  onClick,
  style,
}) {
  const fontSize = FONTSIZE[size];
  const _color = TextColors[color];
  const fontWeight = FONTWEIGHT[weight];
  const textAlign = FONTALIGN[align];

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <p
      className={className}
      onClick={onClick}
      style={{ fontSize, color: _color, fontWeight, textAlign, ...style }}
    >
      {children || text}
    </p>
  );
}

GloryText.propTypes = {
  text: oneOfType([string, number]).isRequired,
  size: oneOf(["small", "xsmall", "mid", "xmid", "large"]),
  color: string,
  weight: string,
  align: string,
  style: shape(CSSStyleDeclaration.prototype),
  children: string,
  className: string,
  onClick: func,
};

GloryText.defaultProps = {
  size: "mid",
  color: "white",
  weight: "normal",
  align: "left",
  children: "",
  style: {},
  className: "",
  onClick: null,
};

export default GloryText;
