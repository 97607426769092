import React from "react";
import { string, func } from "prop-types";

import styled from "styled-components";

import { GloryFlex, GloryImg } from "components";
import { Palette } from "style";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

function FeaturePreviewModal({ img, onHide }) {
  return (
    <Wrapper>
      <GloryFlex
        onClick={onHide}
        background={`${Palette.darkBlack}90`}
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <GloryImg src={img} width="80%" height="80%" />
      </GloryFlex>
    </Wrapper>
  );
}

FeaturePreviewModal.propTypes = {
  img: string.isRequired,
  onHide: func.isRequired,
};

export default FeaturePreviewModal;
