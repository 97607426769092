import React from "react";

import { GLORY_LARGE } from "assets";
import { GloryFlex, GloryHighlight, GloryImg, GloryText } from "components";
import styled from "styled-components";

const Wrapper = styled(GloryFlex)`
  gap: 12rem;
  flex: revert;

  @media screen and (max-width: 1100px) {
    flex-direction: column !important;
    align-items: center !important;
    gap: 1rem !important;
  }

  @media screen and (max-width: 500px) {
    border: 1px solid green;
    text-align: center !important;
  }
`;

function StyledHeader() {
  return (
    <Wrapper>
      <GloryImg src={GLORY_LARGE} style={{ width: "6rem", height: "6rem" }} />
      <GloryText size="large" align="center">
        <GloryHighlight size="large" />, The Roadmap
      </GloryText>
    </Wrapper>
  );
}

export default StyledHeader;
