import React from "react";
import styled from "styled-components";
import SideMenuItem from "./SideMenuItem";

const Wrapper = styled("div")`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    gap: 3rem;
  }

  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

function HeaderSideMenu() {
  return (
    <Wrapper>
      <SideMenuItem to="/" label="Home" />
      <SideMenuItem to="/downloads" label="Downloads" />
      <SideMenuItem to="/roadmap" label="Road map" />
      <SideMenuItem to="/about" label="About" />
    </Wrapper>
  );
}

export default HeaderSideMenu;
