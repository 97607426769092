import { store } from "store";
import { appActions } from "store/App/app.slice";

export const onAppLoad = () => {
  const { dispatch } = store;

  dispatch(appActions.getAllVersions());
  dispatch(appActions.getLatestVersionStatus());
  dispatch(appActions.getUpcomingAppVersions());
};
