import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import DownloadPage from "pages/download";
import Root from "pages";
import HomePage from "pages/home";
import Roadmap from "pages/roadmap";
import AboutPage from "pages/about";

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<HomePage />} />
      <Route path="/downloads" element={<DownloadPage />} />
      <Route path="/roadmap" element={<Roadmap />} />
      <Route path="/about" element={<AboutPage />} />
    </Route>
  )
);
