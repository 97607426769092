import axios from "axios";

class GloryApi {
  #client;

  constructor(baseURL) {
    this.#client = axios.create({ baseURL });
  }

  uploadVerion(payload) {
    return this.#client
      .post("/upload-version", payload, {
        headers: {
          "Content-Type": "Multipart/formData",
        },
      })
      .then(({ data }) => data);
  }

  getAllVersions() {
    return this.#client.get("/versions").then(({ data }) => data);
  }

  getVersion(version) {
    return this.#client.get(`/versions/${version}`).then(({ data }) => data);
  }

  getLatestVersionStatus() {
    return this.#client.get("/latest-status").then(({ data }) => data);
  }

  getUpcomingAppVersions() {
    return this.#client.get("/upcoming-versions").then(({ data }) => data);
  }
}

export const gloryApi = new GloryApi(process.env.REACT_APP_baseURL);
