import React from "react";

import { number, oneOfType, string, shape, bool } from "prop-types";

function GloryImg({ width, height, alt, src, loading, style, cursor, round }) {
  return (
    <img
      style={{ cursor, borderRadius: round ? 999 : undefined, ...style }}
      src={src}
      width={width}
      height={height}
      alt={alt}
      cursor={cursor}
      loading={loading}
    />
  );
}

GloryImg.propTypes = {
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
  alt: string,
  src: string,
  style: shape(CSSStyleDeclaration.prototype),
  cursor: string,
  loading: string,
  round: bool,
};

GloryImg.defaultProps = {
  width: "5rem",
  height: "5rem",
  alt: "picture",
  src: "",
  cursor: "",
  style: {},
  loading: "",
  round: false,
};

export default GloryImg;
