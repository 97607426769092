import React from "react";

import {
  number,
  ReactComponentLike,
  ReactNodeLike,
  string,
  shape,
  oneOfType,
  func,
} from "prop-types";

function GloryFlex({
  flexDirection,
  justifyContent,
  alignItems,
  children,
  flex,
  width,
  height,
  background,
  style,
  className,
  margin,
  padding,
  gap,
  onClick,
}) {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection,
        justifyContent,
        alignItems,
        flex,
        width,
        height,
        background,
        margin,
        padding,
        gap,
        ...style,
      }}
      className={className}
    >
      {children}
    </div>
  );
}

GloryFlex.propTypes = {
  children: oneOfType([ReactNodeLike, ReactComponentLike]).isRequired,
  flexDirection: string,
  justifyContent: string,
  alignItems: string,
  flex: number,
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  background: string,
  style: shape(CSSStyleDeclaration.prototype),
  className: string,
  margin: string,
  padding: string,
  gap: string,
  onClick: func,
};

GloryFlex.defaultProps = {
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flex: 1,
  width: "auto",
  height: "auto",
  background: "inherit",
  style: {},
  className: "",
  margin: "",
  padding: "",
  gap: "",
  onClick: () => null,
};

export default GloryFlex;
