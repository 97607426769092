export const Palette = {
  lightRed: "#ff0000",
  darkRed: "#110101",
  red: "#3a0000",

  darkBlack: "#101010",

  gold: "#e1bc48",

  white: "#fff",
};
