import React from "react";

import {
  oneOfType,
  ReactNodeLike,
  ReactComponentLike,
  string,
  shape,
} from "prop-types";

function GloryLink({ children, href, target, className, style }) {
  return (
    <a
      href={href}
      target={target}
      className={className}
      style={{ textDecoration: "none", cursor: "pointer", ...style }}
    >
      {children}
    </a>
  );
}

GloryLink.propTypes = {
  children: oneOfType([ReactNodeLike, ReactComponentLike]).isRequired,
  href: string,
  target: string,
  style: shape(CSSStyleDeclaration.prototype),
  className: string,
};

GloryLink.defaultProps = {
  href: "#",
  target: "_",
  className: "",
  style: {},
};

export default GloryLink;
