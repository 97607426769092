import React from "react";
import {
  GloryFlex,
  GloryHighlight,
  GloryImg,
  GlorySpace,
  GloryText,
} from "components";
import styled from "styled-components";
import {
  ArtStationIcon,
  DiscordIcon,
  GithubIcon,
  KevlarPNG,
  LinkedInIcon,
  SpartaPNG,
  TwitchIcon,
  YoutubeIcon,
  YuniacPNG,
} from "assets";
import { Link } from "react-router-dom";
import StyledHeader from "shared/header/TitleWithIcon";

const FooterItems = [
  [
    {
      title: "My Repo",
      link: "https://github.com/yuniac",
      img: GithubIcon,
    },
    {
      title: "My linkedin",
      link: "https://www.linkedin.com/in/yusuf-sabbag/",
      img: LinkedInIcon,
    },
    {
      title: "My Server",
      link: "https://discord.gg/5kW6kQe",
      img: DiscordIcon,
    },
    {
      title: "My Twitch",
      link: "https://www.twitch.tv/yuniac",
      img: TwitchIcon,
    },
  ],
  [
    {
      title: "My Channel",
      link: "https://www.youtube.com/@kevlargraphx3562",
      img: YoutubeIcon,
    },
    {
      title: "My Art Station",
      link: "https://www.artstation.com/kevlar_graphx",
      img: ArtStationIcon,
    },
  ],
];

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 120rem;
  width: 100%;
  min-height: 100vh;
`;

function AboutPage() {
  return (
    <Wrapper>
      <GlorySpace />
      <StyledHeader />

      <GlorySpace h="4rem" />

      <GloryFlex
        style={{ maxWidth: "80rem" }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex="revert"
      >
        <GloryText align="center">
          <GloryHighlight /> is a product of years of work, and is also a
          product of games and games development love.
        </GloryText>
        <GloryText align="center">
          I&apos;m currently working on a game that fully utilizes this engine
        </GloryText>
      </GloryFlex>
      <GlorySpace h="4rem" />
      <GloryText size="xmid">
        <GloryHighlight size="xmid" />, The people
      </GloryText>
      <GlorySpace h="4rem" />
      <GloryFlex flexDirection="column" gap="5rem">
        <GloryFlex gap="2rem" alignItems="center" justifyContent="center">
          <GloryImg
            round
            src={SpartaPNG}
            style={{ width: "7rem", height: "7rem" }}
          />
          <GloryFlex flexDirection="column">
            <GloryFlex gap="1rem">
              <GloryText color="red">Spartamania302:</GloryText>
              <GloryText>Project lead</GloryText>
            </GloryFlex>
            <GloryText size="xsmall">
              The root of <GloryHighlight size="xsmall" />, main project leader,
              maintainer and manager
            </GloryText>
          </GloryFlex>
        </GloryFlex>

        <GloryFlex gap="2rem" alignItems="center" justifyContent="center">
          <GloryImg
            src={YuniacPNG}
            style={{ width: "7rem", height: "7rem" }}
            round
          />
          <GloryFlex flexDirection="column" justifyContent="center">
            <GloryFlex gap="1rem">
              <GloryText color="red">Yuniac:</GloryText>
              <GloryText>Website and server engineer</GloryText>
            </GloryFlex>
            <GloryText size="xsmall">
              Project lead behind the server and the website of&nbsp;
              <GloryHighlight size="xsmall" />
            </GloryText>
            <GlorySpace />
            <GloryFlex gap="2rem" alignSelf="center">
              {FooterItems[0].map(({ title, link, img }) => (
                <Link
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",

                    width: "3rem",
                    opacity: 0.7,
                  }}
                  target="_blank"
                  referrerPolicy="no-referrer"
                  key={title}
                  to={link}
                >
                  <GloryImg src={img} width="32px" height="32px" />
                </Link>
              ))}
            </GloryFlex>
          </GloryFlex>
        </GloryFlex>

        <GloryFlex gap="2rem" alignItems="center" justifyContent="center">
          <GloryImg
            src={KevlarPNG}
            style={{ width: "7rem", height: "7rem" }}
            round
          />
          <GloryFlex flexDirection="column">
            <GloryFlex flexDirection="column">
              <GloryFlex gap="1rem">
                <GloryText color="red">Kevlar:</GloryText>
                <GloryText>Main tester and quality assurance person</GloryText>
              </GloryFlex>
              <GloryText size="xsmall">
                Tester, reviewer and vetter of&nbsp;
                <GloryHighlight size="xsmall" />
              </GloryText>
              <GlorySpace />
              <GloryFlex gap="2rem">
                {FooterItems[1].map(({ title, link, img }) => (
                  <Link
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      width: "3rem",
                      opacity: 0.7,
                    }}
                    target="_blank"
                    referrerPolicy="no-referrer"
                    key={title}
                    to={link}
                  >
                    <GloryImg src={img} width="32px" height="32px" />
                  </Link>
                ))}
              </GloryFlex>
            </GloryFlex>
          </GloryFlex>
        </GloryFlex>
      </GloryFlex>
      <GlorySpace h="4rem" />
    </Wrapper>
  );
}

export default AboutPage;
