import { useMediaQueries } from "@react-hook/media-query";
import { GloryFlex, GloryImg, GloryText } from "components";
import { number, string, ReactComponentLike } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Palette } from "style";
import styled from "styled-components";
import FeaturePreviewModal from "./FeaturePreviewModal";

const HoverWrapper = styled.div`
  &:hover {
    border-bottom: ${() => `1px solid ${Palette.gold}`};
  }
`;

const Wrapper = styled.div`
  max-width: 110rem;
  width: 100%;
  padding: 1rem 2rem;

  display: flex;
  flex-direction: ${(props) => (props.index % 2 === 0 ? "row" : "row-reverse")};
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  transition: ${(props) => `${props.speed} all ease-in-out`};
  opacity: ${(props) => (props.isIntersecting ? 1 : 0.1)};

  @media screen and (max-width: 1100px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.isIntersecting ? 1 : 0.1)};
  }
`;

function FeatureHighlight({
  index,
  img,
  title,
  description,
  children,
  speed,
  threshold,
}) {
  const [isIntersecting, setIsIntersecting] = useState();
  const [selectedFeature, setSelectedFeature] = useState();

  const targetRef = useRef(null);
  const observerRef = useRef(null);

  const showModal = (arg) => setSelectedFeature(arg);
  const hideModal = () => setSelectedFeature(undefined);

  const {
    matches: { width: isSmallScreen },
  } = useMediaQueries({ screen: "screen", width: "(max-width: 600px)" });

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { root: null, threshold }
    );
  }, []);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.observe(targetRef.current);
    }
  }, []);

  useEffect(() => {
    if (selectedFeature) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [selectedFeature]);

  return (
    <Wrapper
      index={index}
      ref={targetRef}
      isIntersecting={isIntersecting}
      speed={speed}
    >
      {children || (
        <>
          <GloryFlex
            onClick={() => showModal(img)}
            flex="unset"
            width="clamp(100%, 50%, 110rem)"
          >
            <HoverWrapper>
              <GloryImg
                loading="lazy"
                cursor="pointer"
                src={img}
                width="100%"
                height="auto"
              />
            </HoverWrapper>
          </GloryFlex>
          <GloryFlex
            flexDirection="column"
            flex="unset"
            justifyContent="center"
            alignItems="center"
            width="clamp(100%, 30%, 80rem)"
          >
            <GloryText
              align="center"
              size={isSmallScreen ? "mid" : "large"}
              style={{
                textDecoration: "underline",
                textDecorationColor: Palette.gold,
              }}
            >
              {title}
            </GloryText>
            <GloryText
              style={{ width: "clamp(100%, 100%, 31rem)" }}
              align="center"
              size={isSmallScreen ? "small" : "mid"}
            >
              {description}
            </GloryText>
          </GloryFlex>
        </>
      )}

      {selectedFeature ? (
        <FeaturePreviewModal img={selectedFeature} onHide={hideModal} />
      ) : null}
    </Wrapper>
  );
}

FeatureHighlight.propTypes = {
  img: string,
  title: string.isRequired,
  description: string,
  index: number.isRequired,
  children: ReactComponentLike,
  speed: number,
  threshold: number,
};

FeatureHighlight.defaultProps = {
  img: "",
  description: "",
  children: null,
  speed: "1s",
  threshold: 0.8,
};

export default FeatureHighlight;
