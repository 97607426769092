import { createSelector } from "@reduxjs/toolkit";

const appSliceState = (state) => state.appSlice;

const selectAllAppVersions = createSelector(
  appSliceState,
  (state) => state.allAppVersions || []
);

const selectLatestVersionStatus = createSelector(
  appSliceState,
  (state) => state.latestVersionStatus
);

const selectUpcomingVersions = createSelector(
  appSliceState,
  (state) => state.upcomingAppVersions
);

export const appSelectors = {
  selectAllAppVersions,
  selectLatestVersionStatus,
  selectUpcomingVersions,
};
