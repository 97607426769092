import { LinuxIcon, MacIcon, WindowsIcon } from "assets";
import {
  GloryFlex,
  GloryHighlight,
  GloryImg,
  GloryLink,
  GlorySpace,
  GloryText,
} from "components";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Footer, Header } from "shared";
import { appSelectors } from "store/App/app.selector";
import { Palette } from "style";
import styled from "styled-components";
import VersionsTable from "./VersionsTable";

const PlatformToIcon = {
  windows: WindowsIcon,
  linux: LinuxIcon,
  mac: MacIcon,
};

const Wrapper = styled.div`
  padding: 1rem;
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
`;

function DownloadPage() {
  const latestVersionStatus = useSelector(
    appSelectors.selectLatestVersionStatus
  );
  const { sources = [], appVersion } = latestVersionStatus;

  const getLatestStats = () => {
    if (!sources.length) {
      return [];
    }

    return sources.map(({ platform, ids, _id }) => ({
      id: _id,
      platform,
      links: { installer: ids.installer, compressed: ids.compressed },
    }));
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <GloryFlex
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      gap="2rem"
      margin="0 0 3rem"
    >
      <GloryFlex flexDirection="column" width="100%">
        <Wrapper>
          <GloryFlex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="1rem"
          >
            <GloryText align="center">
              Get the latest version of <GloryHighlight /> now:
            </GloryText>
            <GloryFlex gap="4rem">
              {getLatestStats().map(({ platform, links, id }) => (
                <GloryFlex
                  key={id}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap="0.5rem"
                >
                  <GloryImg
                    src={PlatformToIcon[platform]}
                    style={{ width: "6rem", height: "6rem" }}
                  />
                  <GloryFlex gap="2rem">
                    {links.installer ? (
                      <GloryFlex flexDirection="column">
                        <GloryLink
                          href={`${process.env.REACT_APP_baseURL}/download/version/${appVersion}/format/${links.installer}`}
                          style={{
                            color: Palette.gold,
                            textDecoration: "underline",
                            fontWeight: "bold",
                            width: "4rem",
                          }}
                          textAlign="center"
                        >
                          Installer
                        </GloryLink>
                      </GloryFlex>
                    ) : null}
                    {links.compressed ? (
                      <GloryFlex flexDirection="column">
                        <GloryLink
                          href={`${process.env.REACT_APP_baseURL}/download/version/${appVersion}/format/${links.compressed}`}
                          style={{
                            color: Palette.gold,
                            textDecoration: "underline",
                            fontWeight: "bold",
                            width: "4rem",
                          }}
                          textAlign="center"
                        >
                          ZIP File
                        </GloryLink>
                      </GloryFlex>
                    ) : null}
                  </GloryFlex>
                </GloryFlex>
              ))}
            </GloryFlex>
          </GloryFlex>

          <VersionsTable />
        </Wrapper>
      </GloryFlex>
    </GloryFlex>
  );
}

export default DownloadPage;
