import { number, oneOfType, string } from "prop-types";
import React from "react";

function GlorySpace({ h, w }) {
  return <div style={{ height: h, width: w }} />;
}

GlorySpace.propTypes = {
  h: oneOfType([string, number]),
  w: oneOfType([string, number]),
};

GlorySpace.defaultProps = {
  h: "1rem",
  w: "1rem",
};

export default GlorySpace;
