/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { gloryApi } from "API/GloryApi";

const getAllVersions = createAsyncThunk("/app/all-versions", () =>
  gloryApi.getAllVersions()
);

const getLatestVersionStatus = createAsyncThunk("/app/latest-status", () =>
  gloryApi.getLatestVersionStatus()
);

const getUpcomingAppVersions = createAsyncThunk("/app/upcoming-versions", () =>
  gloryApi.getUpcomingAppVersions()
);

const uploadAVersion = createAsyncThunk("/app/upload-version", (dto) =>
  gloryApi.uploadVerion(dto)
);

const AppSlice = createSlice({
  name: "app",
  initialState: {
    name: "Glory FE",
    allAppVersions: [],
    latestVersionStatus: {},
    upcomingAppVersions: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllVersions.fulfilled, (state, action) => {
      state.allAppVersions = action.payload;
    });

    builder.addCase(getLatestVersionStatus.fulfilled, (state, action) => {
      state.latestVersionStatus = action.payload;
    });

    builder.addCase(getUpcomingAppVersions.fulfilled, (state, action) => {
      state.upcomingAppVersions = action.payload;
    });
  },
});

export default AppSlice.reducer;

export const appActions = {
  ...AppSlice.actions,
  getAllVersions,
  uploadAVersion,
  getLatestVersionStatus,
  getUpcomingAppVersions,
};
