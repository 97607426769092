import { useSelector } from "react-redux";
import React from "react";
import styled from "styled-components";
import { useMediaQueries } from "@react-hook/media-query";
import { format } from "date-fns";

import { LinuxIcon, MacIcon, WindowsIcon } from "assets";
import {
  GloryFlex,
  GloryHighlight,
  GloryImg,
  GloryLink,
  GlorySpace,
  GloryText,
} from "components";
import { FONTSIZE, Palette } from "style";
import { appSelectors } from "store/App/app.selector";

const PlatformToImageMap = {
  windows: WindowsIcon,
  mac: MacIcon,
  linux: LinuxIcon,
};

const FlexValues = {
  version: 2,
  date: 3,
  changes: 4,
  download: 3,
};

const DownloadLink = styled(GloryLink)`
  text-decoration: underline;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 0.2rem;
  margin: 1rem;

  &&&:hover {
    text-decoration: underline;
  }
`;

const TableWrapper = styled.div`
  border: 1px solid yellow;
  max-width: 90rem;
  min-width: 70rem;
  min-height: 20rem;
  margin: 0 auto;
  -webkit-box-shadow: ${() => `-22px 22px 0px -7px ${Palette.red}`};
  -moz-box-shadow: ${() => `-22px 22px 0px -7px ${Palette.red}`};
  box-shadow: ${() => `-22px 22px 0px -7px ${Palette.red}`};

  @media screen and (max-width: 1100px) {
    min-width: 100%;
  }

  .header {
    width: 100%;
    padding: 1.5rem 0 0;
    margin: 0 0 0.5rem;
  }
`;

function VersionsTable() {
  const {
    matches: { width: isSmallScreen },
  } = useMediaQueries({ screen: "screen", width: "(min-width: 1100px)" });

  const versions = useSelector(appSelectors.selectAllAppVersions);

  return (
    <GloryFlex
      flexDirection="column"
      width="100%"
      justifyContent="center"
      alignItems="center"
      gap="1rem"
    >
      <GloryFlex justifyContent="center">
        <GloryText align="center">
          All available to download versions of <GloryHighlight /> can be found
          here:
        </GloryText>
      </GloryFlex>

      <TableWrapper>
        <GloryFlex
          justifyContent="space-between"
          alignItems="flex-start"
          className="header"
        >
          <GloryText
            align="center"
            weight="bold"
            style={{ flex: FlexValues.version }}
          >
            Version
          </GloryText>
          {isSmallScreen ? (
            <GloryText
              align="center"
              weight="bold"
              style={{ flex: FlexValues.date }}
              className="left-border"
            >
              Release Date
            </GloryText>
          ) : null}
          <GloryText
            align="center"
            weight="bold"
            style={{ flex: FlexValues.changes }}
            className="left-border"
          >
            Changes
          </GloryText>
          {isSmallScreen ? (
            <GloryText
              align="center"
              weight="bold"
              style={{ flex: FlexValues.download }}
              className="left-border"
            >
              Download
            </GloryText>
          ) : null}
        </GloryFlex>
        <GlorySpace />
        {versions.map(
          ({ appVersion, releaseDate, sources, isAvailable }, index) => (
            <div
              key={appVersion}
              style={{ background: index % 2 === 0 ? Palette.red : "inherit" }}
            >
              <GloryFlex
                justifyContent="space-between"
                alignItems="center"
                style={{ paddingBottom: ".8rem .5rem" }}
              >
                <GloryText
                  align="center"
                  size="xsmall"
                  style={{ flex: FlexValues.version }}
                >
                  {appVersion}
                </GloryText>
                {isSmallScreen ? (
                  <GloryText
                    align="center"
                    size="xsmall"
                    style={{ flex: FlexValues.date }}
                  >
                    {format(new Date(releaseDate), "RRRR - MMM - do")}
                  </GloryText>
                ) : null}

                <GloryFlex
                  style={{ flex: FlexValues.changes }}
                  justifyContent="center"
                >
                  <GloryLink
                    style={{
                      color: Palette.white,
                      fontSize: FONTSIZE.xsmall,
                      textDecoration: "underline",
                    }}
                    href={`${process.env.REACT_APP_baseURL}/version/${appVersion}/changelog`}
                  >
                    View the patch notes
                  </GloryLink>
                </GloryFlex>
                {isSmallScreen ? (
                  <GloryFlex
                    flexDirection="column"
                    style={{ flex: FlexValues.download }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {sources.map(
                      ({
                        platform,
                        ids: { installer, compressed },
                        available,
                      }) => (
                        <GloryFlex
                          padding="0 .5rem"
                          alignItems="flex-start"
                          gap="0.5rem"
                          key={`item-${platform}-${installer}-${available}`}
                        >
                          {installer ? (
                            <DownloadLink
                              style={{
                                color: available ? Palette.gold : Palette.white,
                                opacity: available ? 1 : "0.6",
                                pointerEvents: available ? "auto" : "none",
                                flex: FlexValues.download,
                              }}
                              target="_self"
                              href={`${process.env.REACT_APP_baseURL}/download/version/${appVersion}/format/${installer}`}
                            >
                              Download Installer
                              <GloryImg
                                src={PlatformToImageMap[platform]}
                                style={{ width: "1rem", height: "1rem" }}
                              />
                            </DownloadLink>
                          ) : null}
                          {compressed ? (
                            <DownloadLink
                              style={{
                                color: available ? Palette.gold : Palette.white,
                                opacity: available ? 1 : "0.6",
                                pointerEvents: available ? "auto" : "none",
                                flex: FlexValues.download,
                              }}
                              href={`${process.env.REACT_APP_baseURL}/download/version/${appVersion}/format/${compressed}`}
                              target="_self"
                            >
                              Download Zip
                              <GloryImg
                                src={PlatformToImageMap[platform]}
                                style={{ width: "1rem", height: "1rem" }}
                              />
                            </DownloadLink>
                          ) : null}
                        </GloryFlex>
                      )
                    )}
                  </GloryFlex>
                ) : null}
              </GloryFlex>
            </div>
          )
        )}
      </TableWrapper>
    </GloryFlex>
  );
}

VersionsTable.propTypes = {};

export default VersionsTable;
