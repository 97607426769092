export const FONTSIZE = {
  small: "1rem",
  xsmall: "1.5rem",
  mid: "2rem",
  xmid: "3rem",
  large: "4rem",
};

export const FONTWEIGHT = {
  light: "light",
  normal: "normal",
  bold: "bold",
};

export const FONTALIGN = {
  left: "left",
  center: "center",
  right: "right",
};
