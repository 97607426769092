import { onAppLoad } from "onAppLoad";
import Root from "pages";
import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import styled from "styled-components";
import { Router } from "./Router";

const Wrapper = styled("div")`
  background: linear-gradient(
    18deg,
    rgba(17, 1, 1, 1) 57%,
    rgba(20, 1, 1, 1) 68%,
    rgba(40, 1, 1, 1) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

function App() {
  useEffect(() => {
    onAppLoad();
    document.title = "Glory";
  }, []);

  return (
    <Wrapper>
      <RouterProvider router={Router} />
    </Wrapper>
  );
}

export default App;
