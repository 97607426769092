import React from "react";
import styled from "styled-components";

import {
  DiscordIcon,
  GithubIcon,
  GLORY_LARGE,
  LinkedInIcon,
  TwitchIcon,
  YoutubeIcon,
} from "assets";
import { GloryFlex, GloryHighlight, GloryImg, GloryText } from "components";
import { Palette } from "style";
import { Link } from "react-router-dom";

const FooterItems = [
  {
    title: "My Repo",
    link: "https://github.com/TheMadDodger/Glory",
    img: GithubIcon,
  },
  {
    title: "My Channel",
    link: "https://www.youtube.com/channel/UCBGcBjLXNeQNoCVB44wMtcg",
    img: YoutubeIcon,
  },
  {
    title: "My Server",
    link: "https://discord.gg/e8Tzqbb",
    img: DiscordIcon,
  },
  {
    title: "My Stream",
    link: "https://www.twitch.tv/spartamania302",
    img: TwitchIcon,
  },
  {
    title: "My Linked-in",
    link: "https://www.linkedin.com/in/robin-cordemans/",
    img: LinkedInIcon,
  },
];

const Wrapper = styled.div`
  padding: 1rem;
  background-color: ${() => Palette.red};
  border-top: ${() => `1px solid ${Palette.gold}20`};
  border-bottom: ${() => `1.5rem solid ${Palette.lightRed}`};

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  width: 100%;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    place-items: center center;
    gap: 2rem;
    height: auto;

    .footer-socials-container {
      flex-wrap: wrap;
      justify-content: space-around !important;
    }
  }
`;

function Footer() {
  return (
    <Wrapper>
      <GloryFlex>
        <GloryHighlight weight="bold" style={{ fontSize: "4rem" }} />
      </GloryFlex>
      <GloryFlex
        flexDirection="column"
        gap="1rem"
        alignItems="center"
        flex="unset"
      >
        <GloryFlex>
          <GloryText color="gold">Stay in touch via:</GloryText>
        </GloryFlex>
        <GloryFlex
          gap="2rem"
          style={{ alignSelf: "center" }}
          className="footer-socials-container"
        >
          {FooterItems.map(({ title, link, img }) => (
            <Link
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: ".2rem",
                width: "6rem",
              }}
              target="_blank"
              referrerPolicy="no-referrer"
              key={title}
              to={link}
            >
              <GloryImg src={img} width="40px" height="40px" />
              <GloryText size="small" align="center" color="gold">
                {title}
              </GloryText>
            </Link>
          ))}
        </GloryFlex>
      </GloryFlex>
      <GloryFlex justifyContent="flex-end">
        <GloryImg src={GLORY_LARGE} style={{ width: "6rem", height: "6rem" }} />
      </GloryFlex>
    </Wrapper>
  );
}

export default Footer;
