import { useMediaQueries } from "@react-hook/media-query";
import {
  ArchitechturePng,
  CustomizabilityPng,
  DevelopmentPng,
  PerformancePng,
} from "assets";
import { GloryFlex, GloryHighlight, GlorySpace, GloryText } from "components";
import React from "react";
import FeatureHighlight from "./FeatureHighlight";

const TableOfContent = [
  {
    title: "Modular Architecture",
    description:
      "Use only what you need and keep game size to a minimum. No more bloat in your code.",
    img: ArchitechturePng,
  },
  {
    title: "Blazing-Fast Performance",
    description:
      "Render thousands of dynamic lights with minimal performance impact at high framerates.",
    img: PerformancePng,
  },
  {
    title: "Fully Customizable",
    description:
      "Write your own modules or extend the editor with your own extensions. Want to use a library? Implement using your own module.",
    img: CustomizabilityPng,
  },
  {
    title: "Development",
    description:
      "Prototype and iterate on your games faster using C# code. The scripting API is simple and understandable.",
    img: DevelopmentPng,
  },
];

function HomeMain() {
  const {
    matches: { width: isSmallScreen },
  } = useMediaQueries({ screen: "screen", width: "(max-width: 600px)" });

  return (
    <GloryFlex flexDirection="column" alignItems="center" width="100%">
      <GloryText size={isSmallScreen ? "mid" : "large"} align="center">
        So, what is <GloryHighlight size={isSmallScreen ? "mid" : "large"} />?
      </GloryText>
      <GlorySpace h="4rem" />
      <GloryFlex
        style={{ marginBottom: "8rem" }}
        flexDirection="column"
        gap="6rem"
        alignItems="center"
        justifyContent="space-evenly"
        width="100%"
      >
        {TableOfContent.map(({ title, description, img }, index) => (
          <FeatureHighlight
            index={index}
            title={title}
            description={description}
            img={img}
            key={title}
          />
        ))}
      </GloryFlex>
    </GloryFlex>
  );
}

export default HomeMain;
