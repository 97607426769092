import React from "react";

import GloryFlex from "components/GloryFlex";
import GloryText from "components/GloryText";
import GloryImg from "components/GloryImg";

import { GLORY_LARGE } from "assets";
import { useMediaQueries } from "@react-hook/media-query";

import { Palette } from "style";
import styled from "styled-components";
import HeaderSideMenu from "./HeaderSideMenu";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${() => Palette.red};
  width: 100%;
  border-top: ${() => `1.5rem solid ${Palette.lightRed}`};
  border-bottom: ${() => `1px solid ${Palette.gold}20`};

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    gap: 2rem;
  }
`;

function HomeHeader() {
  const {
    matches: { width: isSmallScreen },
  } = useMediaQueries({ screen: "screen", width: "(max-width: 600px)" });

  return (
    <Wrapper>
      <GloryFlex flex={1} justifyContent="flex-start" width="100%">
        <HeaderSideMenu />
      </GloryFlex>

      <GloryFlex flex={2} justifyContent="center">
        <GloryText
          weight="bold"
          size={isSmallScreen ? "mid" : "large"}
          align="center"
          color="gold"
        >
          A Modular Game Engine and Editor.
        </GloryText>
      </GloryFlex>
      <GloryFlex flex={1} justifyContent="flex-end">
        <GloryImg src={GLORY_LARGE} style={{ width: "6rem", height: "6rem" }} />
      </GloryFlex>
    </Wrapper>
  );
}

export default HomeHeader;
