import React from "react";

import { oneOf, shape, string } from "prop-types";
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import { FONTSIZE, FONTWEIGHT, TextColors } from "style";

function GloryHighlight({ size, color, weight, children, style }) {
  const fontSize = FONTSIZE[size];
  const _color = TextColors[color];
  const fontWeight = FONTWEIGHT[weight];

  return (
    <span style={{ fontSize, color: _color, fontWeight, ...style }}>
      {children || "Glory"}
    </span>
  );
}

GloryHighlight.propTypes = {
  size: oneOf(["small", "mid", "large"]),
  color: string,
  weight: string,
  children: string,
  style: shape(CSSStyleDeclaration.prototype),
};

GloryHighlight.defaultProps = {
  size: "mid",
  color: "gold",
  weight: "normal",
  children: "",
  style: {},
};

export default GloryHighlight;
