import React from "react";
import { EditorPng } from "assets";

import { Link } from "react-router-dom";
import { GloryFlex, GloryHighlight, GloryImg, GlorySpace } from "components";
import GloryText from "components/GloryText";
import { Palette } from "style";

import styled from "styled-components";

import { Main } from "./components";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 120rem;
  width: 100%;
`;

function HomePage() {
  return (
    <Wrapper>
      <GlorySpace />
      <GloryText align="center" color="white">
        <GloryHighlight /> is an under development, modular game engine and
        editor, written in C++.
      </GloryText>
      <GloryFlex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <GloryImg
          src={EditorPng}
          style={{ width: "clamp(22rem, 70%, 150rem)", height: "auto" }}
        />
        <GloryText size="small" color="grey">
          A screenshot of the editor interface.
        </GloryText>
      </GloryFlex>
      <GlorySpace h="3rem" />

      <Main />

      <GloryFlex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
      >
        <GloryText>
          Ready to use <GloryHighlight />?
        </GloryText>
        <Link
          to="/downloads"
          target="_self"
          style={{
            background: Palette.lightRed,
            padding: "0 1.5rem",
            borderRadius: "5px",
            width: "15rem",
            fontSize: "2rem",
            color: Palette.white,
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            textDecoration: "none",
          }}
        >
          Download
        </Link>
      </GloryFlex>
      <GlorySpace h="3rem" />
    </Wrapper>
  );
}

export default HomePage;
