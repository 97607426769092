import { configureStore, combineReducers } from "@reduxjs/toolkit";

import appSlice from "./App/app.slice";

const reducer = combineReducers({
  appSlice,
});

export const store = configureStore({
  reducer,
});
